@import "~@kaizen/components/dist/styles.css";
*, ::before, ::after{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(1 104 179 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  
}
::backdrop{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(1 104 179 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  
}
body .un-fixed{
    position: fixed
}
body .un-absolute{
    position: absolute
}
body .un-relative{
    position: relative
}
body .un-inset-0{
    inset: 0
}
body .un-left-0{
    left: 0
}
body .un-left-8{
    left: .5rem
}
body .un-left-\[-100\%\]{
    left: -100%
}
body .un-top-0{
    top: 0
}
body .un-top-8{
    top: .5rem
}
body .un-z-\[1029\]{
    z-index: 1029
}
body .un-z-\[1030\]{
    z-index: 1030
}
body .un-z-\[1040\]{
    z-index: 1040
}
body .un-z-\[1050\]{
    z-index: 1050
}
body .un-m-0{
    margin: 0
}
body .un-m-\[-4px_0_5px\]{
    margin: -4px 0 5px
}
body .un-m-\[null_0_null_12px\]{
    margin: null 0 null 12px
}
body .un-mx-0{
    margin-left: 0;
    margin-right: 0
}
body .un-mx-24{
    margin-left: 1.5rem;
    margin-right: 1.5rem
}
body .un-my-0{
    margin-top: 0;
    margin-bottom: 0
}
body .un-my-20{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
}
body .-un-mb-\[11px\]{
    margin-bottom: -11px
}
body .un-ml-0{
    margin-left: 0
}
body .un-ml-24{
    margin-left: 1.5rem
}
body .un-ml-6{
    margin-left: .375rem
}
body .un-ml-auto{
    margin-left: auto
}
body .un-mr-0{
    margin-right: 0
}
body .un-mr-auto{
    margin-right: auto
}
body .un-box-border{
    box-sizing: border-box
}
body .un-block{
    display: block
}
body .un-flex{
    display: flex
}
body .un-hidden{
    display: none
}
body .un-h-20{
    height: 1.25rem
}
body .un-h-48{
    height: 3rem
}
body .un-h-72{
    height: 4.5rem
}
body .un-h-\[60px\]{
    height: 60px
}
body .un-h-\[72px\]{
    height: 72px
}
body .un-h-full{
    height: 100%
}
body .un-h-screen{
    height: 100vh
}
body .un-min-h-48{
    min-height: 3rem
}
body .un-w-48{
    width: 3rem
}
body .un-w-\[3\.75rem\]{
    width: 3.75rem
}
body .un-w-\[calc\(1\.5rem\*5\)\]{
    width: calc(1.5rem * 5)
}
body .un-w-full{
    width: 100%
}
body .un-min-w-\[196px\]{
    min-width: 196px
}
body .un-max-w-\[133px\]{
    max-width: 133px
}
body .un-max-w-\[360px\]{
    max-width: 360px
}
body .un-max-w-\[375px\]{
    max-width: 375px
}
body .un-flex-\[0_0_50\%\]{
    flex: 0 0 50%
}
body .un-flex-\[0_0_auto\]{
    flex: 0 0 auto
}
body .un-flex-\[0_1_100\%\]{
    flex: 0 1 100%
}
body .un-flex-auto{
    flex: 1 1 auto
}
body .un-flex-none{
    flex: none
}
body .un-flex-shrink-0{
    flex-shrink: 0
}
body .un-translate-x-0{
    --tw-translate-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
body .un-translate-x-6{
    --tw-translate-x: .375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
body .un-cursor-pointer{
    cursor: pointer
}
body .un-list-none{
    list-style-type: none
}
body .un-appearance-none{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
}
body .un-flex-col{
    flex-direction: column
}
body .un-flex-wrap{
    flex-wrap: wrap
}
body .un-items-center{
    align-items: center
}
body .un-justify-end{
    justify-content: flex-end
}
body .un-justify-center{
    justify-content: center
}
body .un-justify-between{
    justify-content: space-between
}
body .un-overflow-hidden{
    overflow: hidden
}
body .un-overflow-visible{
    overflow: visible
}
body .un-overflow-y-scroll{
    overflow-y: scroll
}
body .un-rounded{
    border-radius: 7px
}
body .un-rounded-\[7px\]{
    border-radius: 7px
}
body .un-rounded-default{
    border-radius: 7px
}
body .un-rounded-t-\[4px\]{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}
body .un-border{
    border-width: 2px
}
body .un-border-none{
    border-width: 0px
}
body .un-border-b-1{
    border-bottom-width: 1px
}
body .un-border-t-1{
    border-top-width: 1px
}
body .un-border-none{
    border-style: none
}
body .un-border-purple-500\/\[0\.1\]{
    border-color: rgb(132 69 135 / 0.1)
}
body .un-border-transparent{
    border-color: transparent
}
body .un-border-t-\[rgba\(82_78_86\)_0\.1\]{
    border-top-color: rgba(82 78 86) 0.1
}
body .un-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}
body .un-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgb(230 246 255 / var(--tw-bg-opacity))
}
body .un-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgb(249 249 249 / var(--tw-bg-opacity))
}
body .un-bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgb(95 51 97 / var(--tw-bg-opacity))
}
body .un-bg-transparent{
    background-color: transparent
}
body .un-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
body .un-object-contain{
    -o-object-fit: contain;
       object-fit: contain
}
body .un-p-0{
    padding: 0
}
body .un-p-12{
    padding: .75rem
}
body .un-p-\[10px_12px\]{
    padding: 10px 12px
}
body .un-px-0{
    padding-left: 0;
    padding-right: 0
}
body .un-px-12{
    padding-left: .75rem;
    padding-right: .75rem
}
body .un-px-16{
    padding-left: 1rem;
    padding-right: 1rem
}
body .un-px-24{
    padding-left: 1.5rem;
    padding-right: 1.5rem
}
body .un-px-6{
    padding-left: .375rem;
    padding-right: .375rem
}
body .un-px-\[calc\(24px-2px\)\]{
    padding-left: calc(24px - 2px);
    padding-right: calc(24px - 2px)
}
body .un-py-0{
    padding-top: 0;
    padding-bottom: 0
}
body .un-py-12{
    padding-top: .75rem;
    padding-bottom: .75rem
}
body .un-py-\[calc\(12px-2px\)\]{
    padding-top: calc(12px - 2px);
    padding-bottom: calc(12px - 2px)
}
body .un-pb-12{
    padding-bottom: .75rem
}
body .un-pe-12{
    padding-inline-end: .75rem
}
body .un-pl-0{
    padding-left: 0
}
body .un-pr-12{
    padding-right: .75rem
}
body .un-pr-4{
    padding-right: .25rem
}
body .un-pr-\[0\.75rem\]{
    padding-right: 0.75rem
}
body .un-pr-\[12px\]{
    padding-right: 12px
}
body .un-pt-16{
    padding-top: 1rem
}
body .un-text-center{
    text-align: center
}
body .un-font-family-data{
    font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif
}
body .un-font-family-heading{
    font-family: "Inter", "Noto Sans", Helvetica, Arial, sans-serif
}
body .un-text-data-units-sm{
    font-size: 1.125rem
}
body .un-text-heading-4{
    font-size: 1.125rem
}
body .un-text-heading-5{
    font-size: 1rem
}
body .un-font-weight-data{
    font-weight: 700
}
body .un-font-weight-paragraph-bold{
    font-weight: 600
}
body .un-leading-data-sm{
    line-height: 1.5rem
}
body .un-leading-heading-4{
    line-height: 1.5rem
}
body .un-leading-heading-5{
    line-height: 1.5rem
}
body .un-tracking-letter-spacing-normal{
    letter-spacing: normal
}
body .un-text-blue-500{
    --tw-text-opacity: 1;
    color: rgb(1 104 179 / var(--tw-text-opacity))
}
body .un-text-purple-800{
    --tw-text-opacity: 1;
    color: rgb(47 36 56 / var(--tw-text-opacity))
}
body .un-text-purple-800\/\[0\.7\]{
    color: rgb(47 36 56 / 0.7)
}
body .un-text-white{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
body .un-text-white\/\[0\.8\]{
    color: rgb(255 255 255 / 0.8)
}
body .un-no-underline{
    text-decoration-line: none
}
body .un-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
body .un-opacity-0{
    opacity: 0
}
body .un-opacity-50{
    opacity: 0.5
}
body .un-shadow-sm{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 3px 16px 0 rgba(0, 0, 0, 0.06);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 3px 16px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
body .un-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px
}
body .un-transition-\[left\]{
    transition-property: left;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
body .un-transition-opacity{
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
body .un-duration-300{
    transition-duration: 300ms
}
body .un-ease-\[cubic-bezier\(0\.455\,0\.03\,0\.515\,0\.955\)\]{
    transition-timing-function: cubic-bezier(0.455,0.03,0.515,0.955)
}
body .\[clip-path\:inset\(50\%\)\]{
    clip-path: inset(50%)
}
body .\[clip\:rect\(0_0_0_0\)\]{
    clip: rect(0 0 0 0)
}
body .\[text-decoration\:none\]{
    text-decoration: none
}
body .\[transition\:none\]{
    transition: none
}
body .\[transition\:transform_cubic-bezier\(0\.55_0\.085_0\.68_0\.53\)_150ms\;\]{
    transition: transform cubic-bezier(0.55 0.085 0.68 0.53) 150ms;
}
body .before\:un-absolute::before{
    content: var(--tw-content);
    position: absolute
}
body .before\:un-inset-x-6::before{
    content: var(--tw-content);
    left: .375rem;
    right: .375rem
}
body .before\:un-top-0::before{
    content: var(--tw-content);
    top: 0
}
body .before\:un-block::before{
    content: var(--tw-content);
    display: block
}
body .before\:un-h-\[5px\]::before{
    content: var(--tw-content);
    height: 5px
}
body .before\:un-rounded-\[0_0_7px_7px\]::before{
    content: var(--tw-content);
    border-radius: 0 0 7px 7px
}
body .before\:un-content-\[\'\'\]::before{
    --tw-content: '';
    content: var(--tw-content)
}
body .hover\:un-cursor-pointer:hover{
    cursor: pointer
}
body .hover\:un-bg-blue-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(230 246 255 / var(--tw-bg-opacity))
}
body .hover\:\[text-decoration\:none\]:hover{
    text-decoration: none
}
body .focus\:un-opacity-\[1\]:focus{
    opacity: 1
}
body .focus\:un-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px
}
body .focus\:un-outline:focus{
    outline-style: solid
}
body .focus\:un-outline-2:focus{
    outline-width: 2px
}
body .focus\:un-outline-blue-500:focus{
    outline-color: #0168b3
}
body .focus\:\[clip-path\:none\]:focus{
    clip-path: none
}
body .focus\:\[clip\:auto\]:focus{
    clip: auto
}
body .focus-visible\:after\:un-absolute:focus-visible::after{
    content: var(--tw-content);
    position: absolute
}
body .focus-visible\:after\:un-inset-\[calc\(-1\*2px\)\]:focus-visible::after{
    content: var(--tw-content);
    inset: calc(-1 * 2px)
}
body .focus-visible\:after\:un-inset-\[calc\(-1\*calc\(\(2px\*2\)\+1px\)\)\]:focus-visible::after{
    content: var(--tw-content);
    inset: calc(-1 * calc((2px * 2) + 1px))
}
body .focus-visible\:after\:un-inset-\[calc\(-1\*calc\(\(2px\*2\)\+5px\)\)\]:focus-visible::after{
    content: var(--tw-content);
    inset: calc(-1 * calc((2px * 2) + 5px))
}
body .focus-visible\:after\:un-bottom-\[-6px\]:focus-visible::after{
    content: var(--tw-content);
    bottom: -6px
}
body .focus-visible\:after\:un-bottom-\[calc\(-1\*2px\)\]:focus-visible::after{
    content: var(--tw-content);
    bottom: calc(-1 * 2px)
}
body .focus-visible\:after\:un-left-\[-12px\]:focus-visible::after{
    content: var(--tw-content);
    left: -12px
}
body .focus-visible\:after\:un-left-\[calc\(-1\*2px\)\]:focus-visible::after{
    content: var(--tw-content);
    left: calc(-1 * 2px)
}
body .focus-visible\:after\:un-right-\[-12px\]:focus-visible::after{
    content: var(--tw-content);
    right: -12px
}
body .focus-visible\:after\:un-right-\[calc\(-1\*2px\)\]:focus-visible::after{
    content: var(--tw-content);
    right: calc(-1 * 2px)
}
body .focus-visible\:after\:un-top-\[-6px\]:focus-visible::after{
    content: var(--tw-content);
    top: -6px
}
body .focus-visible\:after\:un-top-\[calc\(-1\*2px\)\]:focus-visible::after{
    content: var(--tw-content);
    top: calc(-1 * 2px)
}
body .focus-visible\:after\:focus-visible\:un-rounded-\[10px\]:focus-visible:focus-visible::after{
    content: var(--tw-content);
    border-radius: 10px
}
body .focus-visible\:after\:un-rounded-\[10px\]:focus-visible::after{
    content: var(--tw-content);
    border-radius: 10px
}
body .focus-visible\:after\:un-rounded-focus-ring:focus-visible::after{
    content: var(--tw-content);
    border-radius: 10px
}
body .focus-visible\:after\:focus-visible\:un-border-\[2px\]:focus-visible:focus-visible::after{
    content: var(--tw-content);
    border-width: 2px
}
body .focus-visible\:after\:un-border:focus-visible::after{
    content: var(--tw-content);
    border-width: 2px
}
body .focus-visible\:after\:un-border-solid:focus-visible::after{
    content: var(--tw-content);
    border-style: solid
}
body .focus-visible\:after\:un-border-blue-200:focus-visible::after{
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(189 226 245 / var(--tw-border-opacity))
}
body .focus-visible\:after\:un-border-blue-300:focus-visible::after{
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(115 192 232 / var(--tw-border-opacity))
}
body .focus-visible\:after\:un-border-blue-500:focus-visible::after{
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(1 104 179 / var(--tw-border-opacity))
}
body .focus-visible\:after\:un-bg-transparent:focus-visible::after{
    content: var(--tw-content);
    background-color: transparent
}
body .focus-visible\:after\:un-content-\[\'\'\]:focus-visible::after{
    --tw-content: '';
    content: var(--tw-content)
}
@media (min-width: 1080px){
    body .lg\:un-block{
        display: block
    }
    body .lg\:un-hidden{
        display: none
    }
}
body .rtl\:un-m-\[null_12px_null_0\]:where([dir="rtl"], [dir="rtl"] *){
    margin: null 12px null 0
}
body .rtl\:un-ml-0:where([dir="rtl"], [dir="rtl"] *){
    margin-left: 0
}
body .rtl\:un-ml-auto:where([dir="rtl"], [dir="rtl"] *){
    margin-left: auto
}
body .rtl\:un-mr-0:where([dir="rtl"], [dir="rtl"] *){
    margin-right: 0
}
body .rtl\:un-mr-24:where([dir="rtl"], [dir="rtl"] *){
    margin-right: 1.5rem
}
body .rtl\:un-mr-auto:where([dir="rtl"], [dir="rtl"] *){
    margin-right: auto
}
body .rtl\:-un-translate-x-6:where([dir="rtl"], [dir="rtl"] *){
    --tw-translate-x: -.375rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
body .rtl\:un-pl-12:where([dir="rtl"], [dir="rtl"] *){
    padding-left: .75rem
}
body .rtl\:un-pl-\[0\.75rem\]:where([dir="rtl"], [dir="rtl"] *){
    padding-left: 0.75rem
}
body .rtl\:un-pl-\[12px\]:where([dir="rtl"], [dir="rtl"] *){
    padding-left: 12px
}
body .rtl\:un-pr-0:where([dir="rtl"], [dir="rtl"] *){
    padding-right: 0
}
@media (forced-colors: active){
    body .forced-colors\:un-border-solid{
        border-style: solid
    }
}
body :is(.\[\&\>\*\]\:un-overflow-hidden>*){
    overflow: hidden
}
body :is(.\[\&\>\*\]\:un-overflow-ellipsis>*){
    text-overflow: ellipsis
}
body :is(.\[\&\>\*\]\:un-whitespace-nowrap>*){
    white-space: nowrap
}
body .\[\&button\:hover\]\:un-cursor-pointerbutton:hover{
    cursor: pointer
}
body :is(.active .admin .\[\.active_\.admin_\&\]\:un-text-blue-500){
    --tw-text-opacity: 1;
    color: rgb(1 104 179 / var(--tw-text-opacity))
}
body :is(.active .education .\[\.active_\.education_\&\]\:un-text-blue-500){
    --tw-text-opacity: 1;
    color: rgb(1 104 179 / var(--tw-text-opacity))
}
body :is(.admin .\[\.admin_\&\]\:un-text-blue-500){
    --tw-text-opacity: 1;
    color: rgb(1 104 179 / var(--tw-text-opacity))
}
body :is(.admin .\[\.admin_\&\]\:un-text-purple-800){
    --tw-text-opacity: 1;
    color: rgb(47 36 56 / var(--tw-text-opacity))
}
body :is(.admin .\[\.admin_\&\]\:un-text-purple-800\/\[0\.7\]){
    color: rgb(47 36 56 / 0.7)
}
body :is(.admin .before\:\[\.admin_\&\]\:un-bg-blue-500)::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(1 104 179 / var(--tw-bg-opacity))
}
body :is(.admin .hover\:\[\.admin_\&\]\:un-text-blue-500:hover){
    --tw-text-opacity: 1;
    color: rgb(1 104 179 / var(--tw-text-opacity))
}
body :is(.admin .focus\:\[\.admin_\&\]\:un-text-blue-500:focus){
    --tw-text-opacity: 1;
    color: rgb(1 104 179 / var(--tw-text-opacity))
}
body :is(.admin .navigationItem:focus .\[\.admin_\.navigationItem\:focus_\&\]\:before\:un-absolute)::before{
    content: var(--tw-content);
    position: absolute
}
body :is(.admin .navigationItem:focus .\[\.admin_\.navigationItem\:focus_\&\]\:before\:-un-inset-x-16)::before{
    content: var(--tw-content);
    left: -1rem;
    right: -1rem
}
body :is(.admin .navigationItem:focus .\[\.admin_\.navigationItem\:focus_\&\]\:before\:un-inset-y-\[calc\(0\.75rem\*-1\)\])::before{
    content: var(--tw-content);
    top: calc(0.75rem * -1);
    bottom: calc(0.75rem * -1)
}
body :is(.admin .navigationItem:focus .\[\.admin_\.navigationItem\:focus_\&\]\:before\:un-rounded-\[7px\])::before{
    content: var(--tw-content);
    border-radius: 7px
}
body :is(.admin .navigationItem:focus .\[\.admin_\.navigationItem\:focus_\&\]\:before\:un-bg-blue-100)::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(230 246 255 / var(--tw-bg-opacity))
}
body :is(.admin .navigationItem:focus .\[\.admin_\.navigationItem\:focus_\&\]\:before\:un-content-\[\'\'\])::before{
    --tw-content: '';
    content: var(--tw-content)
}
body :is(.admin .navigationItem:hover .\[\.admin_\.navigationItem\:hover_\&\]\:before\:un-absolute)::before{
    content: var(--tw-content);
    position: absolute
}
body :is(.admin .navigationItem:hover .\[\.admin_\.navigationItem\:hover_\&\]\:before\:-un-inset-x-16)::before{
    content: var(--tw-content);
    left: -1rem;
    right: -1rem
}
body :is(.admin .navigationItem:hover .\[\.admin_\.navigationItem\:hover_\&\]\:before\:un-inset-y-\[calc\(0\.75rem\*-1\)\])::before{
    content: var(--tw-content);
    top: calc(0.75rem * -1);
    bottom: calc(0.75rem * -1)
}
body :is(.admin .navigationItem:hover .\[\.admin_\.navigationItem\:hover_\&\]\:before\:un-rounded-\[7px\])::before{
    content: var(--tw-content);
    border-radius: 7px
}
body :is(.admin .navigationItem:hover .\[\.admin_\.navigationItem\:hover_\&\]\:before\:un-bg-blue-100)::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(230 246 255 / var(--tw-bg-opacity))
}
body :is(.admin .navigationItem:hover .\[\.admin_\.navigationItem\:hover_\&\]\:before\:un-content-\[\'\'\])::before{
    --tw-content: '';
    content: var(--tw-content)
}
body :is(.caLogoContainer:focus-visible .\[\.caLogoContainer\:focus-visible_\&\]\:after\:un-absolute)::after{
    content: var(--tw-content);
    position: absolute
}
body :is(.caLogoContainer:focus-visible .\[\.caLogoContainer\:focus-visible_\&\]\:after\:-un-inset-x-16)::after{
    content: var(--tw-content);
    left: -1rem;
    right: -1rem
}
body :is(.caLogoContainer:focus-visible .\[\.caLogoContainer\:focus-visible_\&\]\:after\:un-inset-y-\[calc\(0\.75rem\*-1\)\])::after{
    content: var(--tw-content);
    top: calc(0.75rem * -1);
    bottom: calc(0.75rem * -1)
}
body :is(.caLogoContainer:focus-visible .\[\.caLogoContainer\:focus-visible_\&\]\:after\:un-m-\[calc\(calc\(\(calc\(2px\*2\)\)\+1px\)\*-1\)\])::after{
    content: var(--tw-content);
    margin: calc(calc((calc(2px * 2)) + 1px) * -1)
}
body :is(.caLogoContainer:focus-visible .\[\.caLogoContainer\:focus-visible_\&\]\:after\:un-rounded-focus-ring)::after{
    content: var(--tw-content);
    border-radius: 10px
}
body :is(.caLogoContainer:focus-visible .\[\.caLogoContainer\:focus-visible_\&\]\:after\:un-border)::after{
    content: var(--tw-content);
    border-width: 2px
}
body :is(.caLogoContainer:focus-visible .\[\.caLogoContainer\:focus-visible_\&\]\:after\:un-border-solid)::after{
    content: var(--tw-content);
    border-style: solid
}
body :is(.caLogoContainer:focus-visible .\[\.caLogoContainer\:focus-visible_\&\]\:after\:un-border-blue-200)::after{
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(189 226 245 / var(--tw-border-opacity))
}
body :is(.caLogoContainer:focus-visible .\[\.caLogoContainer\:focus-visible_\&\]\:after\:un-content-\[\'\'\])::after{
    --tw-content: '';
    content: var(--tw-content)
}
body :is(.default .\[\.default_\&\]\:un-text-white){
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
body :is(.default .\[\.default_\&\]\:un-text-white\/\[0\.7\]){
    color: rgb(255 255 255 / 0.7)
}
body :is(.default .before\:\[\.default_\&\]\:un-bg-white)::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
body :is(.default .hover\:\[\.default_\&\]\:un-text-white:hover){
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
body :is(.default .focus\:\[\.default_\&\]\:un-text-white:focus){
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
body :is(.education .\[\.education_\&\]\:un-text-blue-500){
    --tw-text-opacity: 1;
    color: rgb(1 104 179 / var(--tw-text-opacity))
}
body :is(.education .\[\.education_\&\]\:un-text-purple-800){
    --tw-text-opacity: 1;
    color: rgb(47 36 56 / var(--tw-text-opacity))
}
body :is(.education .\[\.education_\&\]\:un-text-purple-800\/\[0\.7\]){
    color: rgb(47 36 56 / 0.7)
}
body :is(.education .before\:\[\.education_\&\]\:un-bg-blue-500)::before{
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(1 104 179 / var(--tw-bg-opacity))
}
body :is(.education .hover\:\[\.education_\&\]\:un-text-blue-500:hover){
    --tw-text-opacity: 1;
    color: rgb(1 104 179 / var(--tw-text-opacity))
}
body :is(.education .focus\:\[\.education_\&\]\:un-text-blue-500:focus){
    --tw-text-opacity: 1;
    color: rgb(1 104 179 / var(--tw-text-opacity))
}
body :is(.education .caLogoContainer:focus-visible .\[\.education_\.caLogoContainer\:focus-visible_\&\]\:after\:un-border-blue-500)::after{
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(1 104 179 / var(--tw-border-opacity))
}
body :is(.education .navigationItem:focus-visible .\[\.education_\.navigationItem\:focus-visible_\&\]\:after\:un-border-blue-500)::after{
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(1 104 179 / var(--tw-border-opacity))
}
body :is(.education .navigationItem:focus .\[\.education_\.navigationItem\:focus_\&\]\:before\:un-absolute)::before{
    content: var(--tw-content);
    position: absolute
}
body :is(.education .navigationItem:focus .\[\.education_\.navigationItem\:focus_\&\]\:before\:-un-inset-x-16)::before{
    content: var(--tw-content);
    left: -1rem;
    right: -1rem
}
body :is(.education .navigationItem:focus .\[\.education_\.navigationItem\:focus_\&\]\:before\:un-inset-y-\[calc\(0\.75rem\*-1\)\])::before{
    content: var(--tw-content);
    top: calc(0.75rem * -1);
    bottom: calc(0.75rem * -1)
}
body :is(.education .navigationItem:focus .\[\.education_\.navigationItem\:focus_\&\]\:before\:un-rounded-\[7px\])::before{
    content: var(--tw-content);
    border-radius: 7px
}
body :is(.education .navigationItem:focus .\[\.education_\.navigationItem\:focus_\&\]\:before\:un-bg-white\/\[0\.85\])::before{
    content: var(--tw-content);
    background-color: rgb(255 255 255 / 0.85)
}
body :is(.education .navigationItem:focus .\[\.education_\.navigationItem\:focus_\&\]\:before\:un-content-\[\'\'\])::before{
    --tw-content: '';
    content: var(--tw-content)
}
body :is(.education .navigationItem:hover .\[\.education_\.navigationItem\:hover_\&\]\:before\:un-absolute)::before{
    content: var(--tw-content);
    position: absolute
}
body :is(.education .navigationItem:hover .\[\.education_\.navigationItem\:hover_\&\]\:before\:-un-inset-x-16)::before{
    content: var(--tw-content);
    left: -1rem;
    right: -1rem
}
body :is(.education .navigationItem:hover .\[\.education_\.navigationItem\:hover_\&\]\:before\:un-inset-y-\[calc\(0\.75rem\*-1\)\])::before{
    content: var(--tw-content);
    top: calc(0.75rem * -1);
    bottom: calc(0.75rem * -1)
}
body :is(.education .navigationItem:hover .\[\.education_\.navigationItem\:hover_\&\]\:before\:un-rounded-\[7px\])::before{
    content: var(--tw-content);
    border-radius: 7px
}
body :is(.education .navigationItem:hover .\[\.education_\.navigationItem\:hover_\&\]\:before\:un-bg-white\/\[0\.85\])::before{
    content: var(--tw-content);
    background-color: rgb(255 255 255 / 0.85)
}
body :is(.education .navigationItem:hover .\[\.education_\.navigationItem\:hover_\&\]\:before\:un-content-\[\'\'\])::before{
    --tw-content: '';
    content: var(--tw-content)
}
body :is(.local .\[\.local_\&\]\:un-text-white){
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
body :is(.local .\[\.local_\&\]\:un-text-white\/\[0\.8\]){
    color: rgb(255 255 255 / 0.8)
}
body :is(.navigationItem:focus-visible .\[\.navigationItem\:focus-visible_\&\]\:after\:un-absolute)::after{
    content: var(--tw-content);
    position: absolute
}
body :is(.navigationItem:focus-visible .\[\.navigationItem\:focus-visible_\&\]\:after\:-un-inset-x-16)::after{
    content: var(--tw-content);
    left: -1rem;
    right: -1rem
}
body :is(.navigationItem:focus-visible .\[\.navigationItem\:focus-visible_\&\]\:after\:un-inset-y-\[calc\(0\.75rem\*-1\)\])::after{
    content: var(--tw-content);
    top: calc(0.75rem * -1);
    bottom: calc(0.75rem * -1)
}
body :is(.navigationItem:focus-visible .\[\.navigationItem\:focus-visible_\&\]\:after\:un-m-\[calc\(calc\(\(calc\(2px\*2\)\)\+1px\)\*-1\)\])::after{
    content: var(--tw-content);
    margin: calc(calc((calc(2px * 2)) + 1px) * -1)
}
body :is(.navigationItem:focus-visible .\[\.navigationItem\:focus-visible_\&\]\:after\:un-rounded-focus-ring)::after{
    content: var(--tw-content);
    border-radius: 10px
}
body :is(.navigationItem:focus-visible .\[\.navigationItem\:focus-visible_\&\]\:after\:un-border)::after{
    content: var(--tw-content);
    border-width: 2px
}
body :is(.navigationItem:focus-visible .\[\.navigationItem\:focus-visible_\&\]\:after\:un-border-solid)::after{
    content: var(--tw-content);
    border-style: solid
}
body :is(.navigationItem:focus-visible .\[\.navigationItem\:focus-visible_\&\]\:after\:un-border-blue-200)::after{
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(189 226 245 / var(--tw-border-opacity))
}
body :is(.navigationItem:focus-visible .\[\.navigationItem\:focus-visible_\&\]\:after\:un-content-\[\'\'\])::after{
    --tw-content: '';
    content: var(--tw-content)
}
body :is(.navigationItem:focus .\[\.navigationItem\:focus_\&\]\:before\:un-absolute)::before{
    content: var(--tw-content);
    position: absolute
}
body :is(.navigationItem:focus .\[\.navigationItem\:focus_\&\]\:before\:-un-inset-x-16)::before{
    content: var(--tw-content);
    left: -1rem;
    right: -1rem
}
body :is(.navigationItem:focus .\[\.navigationItem\:focus_\&\]\:before\:un-inset-y-\[calc\(0\.75rem\*-1\)\])::before{
    content: var(--tw-content);
    top: calc(0.75rem * -1);
    bottom: calc(0.75rem * -1)
}
body :is(.navigationItem:focus .\[\.navigationItem\:focus_\&\]\:before\:un-rounded-\[7px\])::before{
    content: var(--tw-content);
    border-radius: 7px
}
body :is(.navigationItem:focus .\[\.navigationItem\:focus_\&\]\:before\:un-bg-white\/\[0\.1\])::before{
    content: var(--tw-content);
    background-color: rgb(255 255 255 / 0.1)
}
body :is(.navigationItem:focus .\[\.navigationItem\:focus_\&\]\:before\:un-content-\[\'\'\])::before{
    --tw-content: '';
    content: var(--tw-content)
}
body :is(.navigationItem:hover .\[\.navigationItem\:hover_\&\]\:before\:un-absolute)::before{
    content: var(--tw-content);
    position: absolute
}
body :is(.navigationItem:hover .\[\.navigationItem\:hover_\&\]\:before\:-un-inset-x-16)::before{
    content: var(--tw-content);
    left: -1rem;
    right: -1rem
}
body :is(.navigationItem:hover .\[\.navigationItem\:hover_\&\]\:before\:un-inset-y-\[calc\(0\.75rem\*-1\)\])::before{
    content: var(--tw-content);
    top: calc(0.75rem * -1);
    bottom: calc(0.75rem * -1)
}
body :is(.navigationItem:hover .\[\.navigationItem\:hover_\&\]\:before\:un-rounded-\[7px\])::before{
    content: var(--tw-content);
    border-radius: 7px
}
body :is(.navigationItem:hover .\[\.navigationItem\:hover_\&\]\:before\:un-bg-white\/\[0\.1\])::before{
    content: var(--tw-content);
    background-color: rgb(255 255 255 / 0.1)
}
body :is(.navigationItem:hover .\[\.navigationItem\:hover_\&\]\:before\:un-content-\[\'\'\])::before{
    --tw-content: '';
    content: var(--tw-content)
}
body :is(.tier4 .\[\.tier4_\&\]\:un-px-12){
    padding-left: .75rem;
    padding-right: .75rem
}
body :is(.tier4 .\[\.tier4_\&\]\:un-py-0){
    padding-top: 0;
    padding-bottom: 0
}
body :is(.tier4 .\[\.tier4_\&\]\:un-text-heading-5){
    font-size: 1rem
}
body :is(.tier4 .navigationItem:focus .\[\.tier4_\.navigationItem\:focus_\&\]\:before\:un-inset-x-\[calc\(0\.75rem\*-1\)\])::before{
    content: var(--tw-content);
    left: calc(0.75rem * -1);
    right: calc(0.75rem * -1)
}
body :is(.tier4 .navigationItem:hover .\[\.tier4_\.navigationItem\:hover_\&\]\:before\:un-inset-x-\[calc\(0\.75rem\*-1\)\])::before{
    content: var(--tw-content);
    left: calc(0.75rem * -1);
    right: calc(0.75rem * -1)
}
body :is(.tier5 .\[\.tier5_\&\]\:un-px-12){
    padding-left: .75rem;
    padding-right: .75rem
}
body :is(.tier5 .\[\.tier5_\&\]\:un-py-0){
    padding-top: 0;
    padding-bottom: 0
}
body :is(.tier5 .\[\.tier5_\&\]\:un-text-heading-6){
    font-size: 0.875rem
}
body :is(.tier5 .navigationItem:focus .\[\.tier5_\.navigationItem\:focus_\&\]\:before\:un-inset-x-\[calc\(0\.75rem\*-1\)\])::before{
    content: var(--tw-content);
    left: calc(0.75rem * -1);
    right: calc(0.75rem * -1)
}
body :is(.tier5 .navigationItem:hover .\[\.tier5_\.navigationItem\:hover_\&\]\:before\:un-inset-x-\[calc\(0\.75rem\*-1\)\])::before{
    content: var(--tw-content);
    left: calc(0.75rem * -1);
    right: calc(0.75rem * -1)
}
body :is(dir='rtl' .\[dir\=\'rtl\'_\&\]\:un-ml-0){
    margin-left: 0
}
body :is(dir='rtl' .\[dir\=\'rtl\'_\&\]\:un-mr-6){
    margin-right: .375rem
}

